// 暴漏 window.$
import 'jquery'

// import global css
import './assets/styles/global.scss'

// import components
import './components'

import 'swiper/swiper-bundle.min.css'
// import Swiper from 'swiper/js/swiper.min.js'

// import './assets/scripts/common.js'

// import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

// 测试视频地址
// http://video.websiter.com.cn/video.webm
